import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import {axiosInstance} from "@/http/instance";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css";
import "@/assets/css/common.css";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axiosInstance;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

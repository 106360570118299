import {Message} from "element-ui";
import AxiosInstance from "@/http/baseAxios";

const axiosInstance = new AxiosInstance(
	(config) => {
		return config;
	},
	(error) => error,
	(response) => {
		if (response.data.code !== "0" && response.data.msg) {
			Message({
				message: response.data.msg,
				showClose: true,
				type: "error",
			});
		}
		return response.data;
	},
	(error) => {
		if (error.response && error.response.status === 500) {
			Message({
				message: "服务器内部错误，请联系相关人员反馈问题！",
				showClose: true,
				type: "error",
			});
		}
		return error;
	},
	{
		baseURL: "http://39.98.193.239:9999", // 正式环境
		// baseURL: "http://124.71.224.87:1099", // 测试环境
		// baseURL: "http://172.16.2.235:8080", // 本地环境
	},
);

export {axiosInstance};

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: () => import("@/components/TheLayout.vue"),
		children: [
			{
				path: "/",
				name: "网站首页",
				component: () => import("@/views/HomePage.vue"),
			},
			{
				path: "/article-paging/:catid",
				name: "文章列表",
				component: () => import("@/views/ArticlePaging.vue"),
			},
			{
				path: "/article-content/:catid/:id/:updatetime/:type/:click",
				name: "文章详情",
				component: () => import("@/views/ArticleContent.vue"),
			},
		],
	},
];

const router = new VueRouter({
	routes,
});

export default router;

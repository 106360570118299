import Axios from "axios";

class AxiosInstance {
	constructor(requestSuccess, requestError, responseSuccess, responseError, config = {}) {
		this.instance = Axios.create(config);
		this.instance.interceptors.request.use(requestSuccess, requestError);
		this.instance.interceptors.response.use(responseSuccess, responseError);
	}

	request(url, method, {data = undefined, params = undefined, config = {}} = {}) {
		return this.instance.request({url, method, data, params, ...config});
	}
}

export default AxiosInstance;
